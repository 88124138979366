

































































































































import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
import * as Settings from "@/settings";
import LocalStorage from "@/core/utils/LocalStorage";
import {
  StripeAddressElementChangeEvent,
  StripeElementLocale,
  StripeElements,
  StripePaymentElementChangeEvent,
} from "@stripe/stripe-js";
import { Action, Getter } from "vuex-class";
import { validationMixin } from "vuelidate";
import {
  helpers,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";
import { Validations } from "vuelidate-property-decorators";
import { EUVat, EUVatRate } from "@/core/plugins/eu-vat-rates";
import api from "@/core/utils/api";

@Component({
  mixins: [validationMixin],
  computed: {
    LocalStorage() {
      return LocalStorage;
    },
  },
  components: {
    StripeElementPayment,
  },
})
export default class StripePaymentElementForm extends Vue {
  @Prop({ default: () => null }) pricing!: {
    tier: string;
    cycle: string;
  };
  @Prop({ default: () => 0 }) grossAmount!: number;
  @PropSync("stepProp", { type: Number }) step!: number;
  @PropSync("checkoutEnabledProp", { type: Boolean }) checkoutEnabled!: boolean;
  @PropSync("paymentElementRefProp", {})
  paymentElementRef!: HTMLFormElement | null;
  @PropSync("vatIdProp", { type: String }) vatId!: string;
  @PropSync("companyNameProp", { type: String }) companyName!: string;
  @PropSync("countryProp", { type: String }) country!: string;
  @PropSync("buyAsCompanyProp", { type: Boolean }) buyAsCompany!: boolean;
  @PropSync("vatValidatedProp", { type: Boolean }) vatValidated!: boolean;
  @PropSync("euVatProp", {}) euVat!: EUVatRate;
  @Getter("profile/getUserName") userName!: string;
  @Getter("profile/getUserEmail") userEmail!: string;
  @Action("displaySnackbar") displaySnackbar!: (msg: string) => void;

  pk = Settings.paymentSettings.publishableKey;
  elementsOptions = {
    appearance: { theme: "stripe" },
    testMode: Settings.paymentSettings.testMode,
    mode: "subscription",
    currency: "eur",
    amount: this.grossAmount,
    loader: "always",
  };
  locale = LocalStorage.getLocale();
  createOptions = {
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
  };
  get addressOptions() {
    return {
      mode: "billing",
      fields: {
        phone: "always",
      },
      display: {
        name: "full",
      },
      defaultValues: {
        name: this.userName,
        address: {
          country: "DE",
          //city: "New York",
          //line1: "123 Main St",
          //line2: "Floor 45",
          //postal_code: "10001",
          //state: "NY",
        },
      },
    };
  }
  get linkAuthOptions() {
    return {
      defaultValues: {
        email: this.userEmail,
      },
    };
  }
  confirmParams = {
    // return_url: // success url -> set in PurchaseOverview
  };
  paymentRefPolling: any = undefined;

  paymentComplete = false;
  addressComplete = false;
  vatComplete = true;
  nextEnabled = false;

  vatServiceValidated = true;
  vatServiceError = "";
  vatServiceLoading = false;

  @Validations()
  validations() {
    if (this.buyAsCompany)
      return {
        companyName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(200),
        },
        vatId: {
          required,
          vatIdRules: this.vatIdRules,
        },
      };
  }

  get isEUCountry() {
    let euCountries = [...Object.keys(EUVat.rates)];

    return euCountries.includes(this.country);
  }

  get vatIdRules() {
    if (this.euVat?.validation_rule)
      return helpers.regex("vatIdRules", this.euVat.validation_rule);

    // Covers all non EU patterns supported by Stripe: https://docs.stripe.com/invoicing/customer/tax-ids
    return helpers.regex("vatIdRules", /^[0-9A-Z-. ]{5,20}$/);
  }

  get vatIdErrors() {
    const errors: any[] = [];
    if (!this.$v.vatId!.$dirty) return errors;
    !this.$v.vatId!.required &&
      errors.push(this.$t("subscription.vatIdRequired"));
    !this.$v.vatId!.vatIdRules &&
      errors.push(this.$t("subscription.vatIdInvalid"));
    return errors;
  }

  get companyNameErrors() {
    const errors: any[] = [];
    if (!this.$v.companyName!.$dirty) return errors;
    !this.$v.companyName!.required &&
      errors.push(this.$t("subscription.companyNameRequired"));
    !this.$v.companyName!.minLength &&
      errors.push(this.$t("subscription.companyNameMin"));
    return errors;
  }

  async validateVatInput() {
    await this.$nextTick().then(() => {
      if (this.buyAsCompany) {
        this.vatComplete =
          !(this.vatIdErrors.length > 0) &&
          !(this.companyNameErrors.length > 0);
        if (this.vatId && this.companyName)
          this.vatValidated = this.vatComplete;
      } else {
        this.vatComplete = true;
        this.vatServiceValidated = true;
        this.vatValidated = false;
      }

      this.validateNext();
    });
  }

  async validateVatService() {
    if (this.isEUCountry) {
      this.$v.$touch();
      this.vatServiceValidated = false;
      if (!this.vatId) {
        this.vatServiceError = this.$t("subscription.vatIdRequired").toString();
        this.displaySnackbar(this.vatServiceError);
        return;
      }
      if (!this.vatValidated) {
        this.vatServiceError = this.$t("subscription.vatIdInvalid").toString();
        this.displaySnackbar(this.vatServiceError);
        return;
      }

      const data = { vatId: this.vatId };
      const endpoint = "/api/Purchase/ValidateVat";
      this.vatServiceLoading = true;

      return await api
        .post(endpoint, data)
        .then((result: any) => {
          if (result.errors.length) {
            console.log(result.errors[0].code, result.errors[0].description);
            this.displaySnackbar(
              this.$t("subscription.couldNotValidate").toString(),
            );
            this.vatServiceLoading = false;
            return;
          }
          setTimeout(() => {
            // Vat ID is valid and exists, add more checks here e.g. compare company name/address
            if (result.succeeded) this.vatServiceValidated = result.succeeded;
            this.validateNext();
            this.displaySnackbar(
              this.$t("subscription.vatIdConfirmed").toString(),
            );
            this.vatServiceLoading = false;
          }, 900);
        })
        .catch(error => {
          if (error.description === "MS_UNAVAILABLE") {
            this.vatServiceError = this.$t(
              "subscription.vatServiceDown",
            ).toString();
            this.displaySnackbar(this.vatServiceError);
            this.vatServiceLoading = false;
            return;
          }
          if (error.description === "MS_MAX_CONCURRENT_REQ") {
            this.vatServiceError = this.$t(
              "subscription.vatServiceOverload",
            ).toString();
            this.displaySnackbar(this.vatServiceError);
            this.vatServiceLoading = false;
            return;
          }
        });
    }
  }

  @Watch("vatServiceValidated")
  logVatServiceValidated() {
    console.debug("vatServiceValidated", this.vatServiceValidated);
  }

  async validatePaymentMethod(event: StripePaymentElementChangeEvent) {
    // Simply checks everything with potential valid input according
    // to Stripe: https://docs.stripe.com/js/element/events/on_change?type=paymentElement
    this.paymentComplete = event.complete;
    this.validateNext();
  }

  async validateAddress(event: StripeAddressElementChangeEvent) {
    this.addressComplete = event.complete;
    this.validateNext();
    // Forward country
    this.country = event.value.address.country;
  }

  mountAddressElement() {
    const elements = ((this.$refs.paymentRef as HTMLFormElement)
      ?.elements as unknown) as StripeElements;
    // @ts-ignore
    const addressElement = elements.create("address", this.addressOptions);
    addressElement.mount("#address-element");

    const linkAuthElement = elements.create(
      "linkAuthentication",
      this.linkAuthOptions,
    );
    linkAuthElement.mount("#link-auth-element");

    addressElement.on("change", event => {
      this.validateAddress(event);
    });
  }

  cancel() {
    this.step = 1;
    this.$router.push("/subscription/pricing");
  }

  next() {
    this.$v.$touch();
    if (this.vatComplete && (this.vatServiceValidated || !this.isEUCountry))
      this.step = 2;
    else
      this.displaySnackbar(
        this.$t("subscription.companyIncomplete").toString(),
      );

    this.paymentElementRef = this.$refs.paymentRef as HTMLFormElement;
  }

  validateNext() {
    this.paymentComplete &&
    this.addressComplete &&
    this.vatComplete &&
    (this.vatServiceValidated || !this.isEUCountry)
      ? (this.nextEnabled = true)
      : (this.nextEnabled = false);
    // Enable checkout separately in case of more next steps
    this.paymentComplete &&
    this.addressComplete &&
    this.vatComplete &&
    (this.vatServiceValidated || !this.isEUCountry)
      ? (this.checkoutEnabled = true)
      : (this.checkoutEnabled = false);
  }

  @Watch("country", { immediate: true })
  async updateVatValidation() {
    this.vatServiceValidated = false;
    await this.validateVatInput();
  }

  watchLocalStorage = (e: any) => {
    e.stopImmediatePropagation();

    this.locale = e.detail?.storage;
    console.log("get storage event:", this.locale);

    const paymentElement = this.$refs?.paymentRef as HTMLFormElement;
    const elements = (paymentElement?.elements as unknown) as StripeElements;

    elements.update({ locale: this.locale as StripeElementLocale });
  };

  mountLocalStorageWatcher() {
    window.addEventListener(
      "locale-localstorage-changed",
      this.watchLocalStorage,
    );
  }

  created() {
    // Conditional mount Address & Link Elements
    if (!this.paymentRefPolling)
      this.paymentRefPolling = setInterval(() => {
        // Wait till payment is mounted and user is available for prefill
        if (this.$refs.paymentRef && this.userName && this.userEmail) {
          this.mountAddressElement();
          this.mountLocalStorageWatcher();
          clearInterval(this.paymentRefPolling);
          this.paymentRefPolling = undefined;
        }
      }, 200);
  }

  beforeDestroy() {
    window.removeEventListener(
      "locale-localstorage-changed",
      this.watchLocalStorage,
    );
  }
}
