// TODO add to fork of benbucksch/eu-vat-rates on Github
export const EUVat: EUVatData = {
  source:
    "https://europa.eu/youreurope/business/taxation/vat/vat-rules-rates/index_en.htm#inline-nav-8",
  last_updated: "2024-06-17T05:08Z",
  disclaimer:
    "This data is compiled from official European Commission and other sources to be as accurate as possible, however no guarantee of accuracy is provided. Use at your own risk. Don't trust random people on the internet without doing your own research.",

  rates: {
    AT: {
      country: "Austria",
      vat_name: "Umsatzsteuer",
      vat_abbr: "USt",
      standard_rate: 20.0,
      reduced_rate: 10.0,
      reduced_rate_alt: 13.0,
      super_reduced_rate: false,
      parking_rate: 13.0,
      validation_rule: /^(AT)?U[0-9]{8}$/,
    },
    BE: {
      country: "Belgium",
      vat_name: "Taxe sur la valeur ajoutée",
      vat_abbr: "TVA/BTW",
      standard_rate: 21.0,
      reduced_rate: 6.0,
      reduced_rate_alt: 12.0,
      super_reduced_rate: false,
      parking_rate: 12.0,
      validation_rule: /^(BE)?0[0-9]{9}$/,
    },
    BG: {
      country: "Bulgaria",
      vat_name: "Dana Dobavena Stoynost",
      vat_abbr: "DDS",
      standard_rate: 20.0,
      reduced_rate: 9.0,
      reduced_rate_alt: false,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(BG)?[0-9]{9,10}$/,
    },
    CY: {
      country: "Cyprus",
      vat_name: "Foros prostithemenis axias",
      vat_abbr: "FPA",
      standard_rate: 19.0,
      reduced_rate: 5.0,
      reduced_rate_alt: 9.0,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(CY)?[0-9]{8}L$/,
    },
    CZ: {
      country: "Czech Republic",
      vat_name: "Dani z pridane hotnoty",
      vat_abbr: "DPH",
      standard_rate: 21.0,
      reduced_rate: 12.0,
      reduced_rate_alt: 15.0,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(CZ)?[0-9]{8,10}$/,
    },
    DE: {
      country: "Germany",
      vat_name: "Umsatzsteuer",
      vat_abbr: "USt",
      standard_rate: 19.0,
      reduced_rate: 7.0,
      reduced_rate_alt: false,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(DE)?[0-9]{9}$/,
    },
    DK: {
      country: "Denmark",
      vat_name: "Omsaetningsavgift",
      vat_abbr: "MOMS",
      standard_rate: 25.0,
      reduced_rate: false,
      reduced_rate_alt: false,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(DK)?[0-9]{8}$/,
    },
    EE: {
      country: "Estonia",
      vat_name: "Käibemaks",
      vat_abbr: "KMKR",
      standard_rate: 22.0,
      reduced_rate: 9.0,
      reduced_rate_alt: false,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(EE)?[0-9]{9}$/,
    },
    GR: {
      country: "Greece",
      vat_name: "Foros prostithemenis axias",
      vat_abbr: "FPA",
      standard_rate: 24.0,
      reduced_rate: 6.0,
      reduced_rate_alt: 13.0,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(EL|GR)?[0-9]{9}$/,
    },
    ES: {
      country: "Spain",
      vat_name: "Impuesto sobre el valor añadido",
      vat_abbr: "IVA",
      standard_rate: 21.0,
      reduced_rate: 10.0,
      reduced_rate_alt: false,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]$/,
    },
    FI: {
      country: "Finland",
      vat_name: "Arvonlisävero",
      vat_abbr: "ALV",
      standard_rate: 24.0,
      reduced_rate: 10.0,
      reduced_rate_alt: 14.0,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(FI)?[0-9]{8}$/,
    },
    FR: {
      country: "France",
      vat_name: "Taxe sur la valeur ajoutée",
      vat_abbr: "TVA",
      standard_rate: 20.0,
      reduced_rate: 5.5,
      reduced_rate_alt: 10.0,
      super_reduced_rate: 2.1,
      parking_rate: false,
      validation_rule: /^(FR)?[0-9A-Z]{2}[0-9]{9}$/,
    },
    HR: {
      country: "Croatia",
      vat_name: "Porez na dodanu vrijednost",
      vat_abbr: "PDV",
      standard_rate: 25.0,
      reduced_rate: 5.0,
      reduced_rate_alt: 13.0,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(HR)?[0-9]{11}$/,
    },
    HU: {
      country: "Hungary",
      vat_name: "Általános forgalmi adó",
      vat_abbr: "AFA",
      standard_rate: 27.0,
      reduced_rate: 5.0,
      reduced_rate_alt: 18.0,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(HU)?[0-9]{8}$/,
    },
    IE: {
      country: "Ireland",
      vat_name: "Value added tax",
      vat_abbr: "VAT",
      standard_rate: 23.0,
      reduced_rate: 9.0,
      reduced_rate_alt: 13.5,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(IE)?[0-9]{1}[0-9A-Z]{1}[0-9]{5}[A-Z]{1,2}$/,
    },
    IT: {
      country: "Italy",
      vat_name: "Imposta sul valore aggiunto",
      vat_abbr: "IVA",
      standard_rate: 22.0,
      reduced_rate: 5.0,
      reduced_rate_alt: 10.0,
      super_reduced_rate: 4.0,
      parking_rate: false,
      validation_rule: /^(IT)?[0-9]{11}$/,
    },
    LT: {
      country: "Lithuania",
      vat_name: "Pridetines vertes mokestis",
      vat_abbr: "PVM",
      standard_rate: 21.0,
      reduced_rate: 5.0,
      reduced_rate_alt: 9.0,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(LT)?([0-9]{9}|[0-9]{12})$/,
    },
    LU: {
      country: "Luxembourg",
      vat_name: "Taxe sur la valeur ajoutée",
      vat_abbr: "TVA",
      standard_rate: 17.0,
      reduced_rate: 8.0,
      reduced_rate_alt: false,
      super_reduced_rate: 3.0,
      parking_rate: 14.0,
      validation_rule: /^(LU)?[0-9]{8}$/,
    },
    LV: {
      country: "Latvia",
      vat_name: "Pievienotas vertibas nodoklis",
      vat_abbr: "PVN",
      standard_rate: 21.0,
      reduced_rate: 5.0,
      reduced_rate_alt: 12.0,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(LV)?[0-9]{11}$/,
    },
    MT: {
      country: "Malta",
      vat_name: "Value added tax",
      vat_abbr: "VAT",
      standard_rate: 18.0,
      reduced_rate: 5.0,
      reduced_rate_alt: 7.0,
      super_reduced_rate: false,
      parking_rate: 12.0,
      validation_rule: /^(MT)?[0-9]{8}$/,
    },
    NL: {
      country: "Netherlands",
      vat_name: "Omzetbelasting",
      vat_abbr: "OB",
      standard_rate: 21.0,
      reduced_rate: 9.0,
      reduced_rate_alt: false,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(NL)?[0-9]{9}B[0-9]{2}$/,
    },
    PL: {
      country: "Poland",
      vat_name: "Podatek od towaròw i uslug",
      vat_abbr: "VAT",
      standard_rate: 23.0,
      reduced_rate: 5.0,
      reduced_rate_alt: 8.0,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(PL)?[0-9]{10}$/,
    },
    PT: {
      country: "Portugal",
      vat_name: "Imposto sobre o valor acrescentado",
      vat_abbr: "IVA",
      standard_rate: 23.0,
      reduced_rate: 6.0,
      reduced_rate_alt: 13.0,
      super_reduced_rate: false,
      parking_rate: 13.0,
      validation_rule: /^(PT)?[0-9]{9}$/,
    },
    RO: {
      country: "Romania",
      vat_name: "Taxa pe valoarea adãugata",
      vat_abbr: "TVA",
      standard_rate: 19.0,
      reduced_rate: 5.0,
      reduced_rate_alt: 9.0,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(RO)?[0-9]{2,10}$/,
    },
    SE: {
      country: "Sweden",
      vat_name: "Mervärdeskatt",
      vat_abbr: "ML",
      standard_rate: 25.0,
      reduced_rate: 6.0,
      reduced_rate_alt: 12.0,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(SE)?[0-9]{12}$/,
    },
    SI: {
      country: "Slovenia",
      vat_name: "Davek na dodano vred nost",
      vat_abbr: "DDV",
      standard_rate: 22.0,
      reduced_rate: 5.0,
      reduced_rate_alt: 9.5,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(SI)?[0-9]{8}$/,
    },
    SK: {
      country: "Slovakia",
      vat_name: "Dan z pridanej hodnoty",
      vat_abbr: "DPH",
      standard_rate: 20.0,
      reduced_rate: 10.0,
      reduced_rate_alt: false,
      super_reduced_rate: false,
      parking_rate: false,
      validation_rule: /^(SK)?[0-9]{10}$/,
    },
  },
};

export interface EUVatRate {
  country: string;
  vat_name: string;
  vat_abbr: string;
  standard_rate: number | boolean;
  reduced_rate: number | boolean;
  reduced_rate_alt: number | boolean;
  super_reduced_rate: number | boolean;
  parking_rate: number | boolean;
  validation_rule: any;
}
export type EUVatRates = { [id: string]: EUVatRate };

export interface EUVatData {
  source: string;
  last_updated: string;
  disclaimer: string;
  rates: EUVatRates;
}
