import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"linkAuthRef",staticClass:"px-1",attrs:{"id":"link-auth-element"}}),_c('div',{ref:"addressRef",staticClass:"px-1 my-8",attrs:{"id":"address-element"}}),_c('stripe-element-payment',{ref:"paymentRef",attrs:{"pk":_vm.pk,"elements-options":_vm.elementsOptions,"confirm-params":_vm.confirmParams,"create-options":_vm.createOptions,"locale":_vm.locale},on:{"element-change":_vm.validatePaymentMethod}}),_c('div',{staticClass:"px-1 my-8"},[_c(VCheckbox,{staticStyle:{"width":"fit-content"},attrs:{"label":_vm.$t('subscription.buyAsCompany')},on:{"change":function () {
          _vm.vatServiceValidated = _vm.buyAsCompany;
          // Only trigger error if text input was made
          _vm.vatId && _vm.companyName && this$1.$v.$touch();
          _vm.validateVatInput();
          // Reset to prevent company/vat init in error state
          !_vm.vatId && !_vm.companyName && this$1.$v.$reset();

          _vm.$nextTick(function () {
            // @ts-ignore
            _vm.buyAsCompany && _vm.$refs.companyName.focus();
          });
        }},model:{value:(_vm.buyAsCompany),callback:function ($$v) {_vm.buyAsCompany=$$v},expression:"buyAsCompany"}}),(_vm.buyAsCompany)?_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.validateVatService($event)}}},[_c(VLabel,[_vm._v(_vm._s(_vm.$t("subscription.vatData")))]),_c(VTextField,{ref:"companyName",staticClass:"input-fix mb-4",attrs:{"outlined":"","dense":"","error-messages":_vm.companyNameErrors,"placeholder":_vm.$t('subscription.enterCompanyName')},on:{"blur":function () {
            this$1.$v.companyName.$touch();
            _vm.validateVatInput();
          },"change":function () {
            _vm.vatServiceValidated = false;
            _vm.validateVatInput();
          },"keyup":[_vm.validateVatInput,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
            // @ts-ignore
            _vm.$refs.vatId.focus();
          })($event)}]},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c(VTextField,{ref:"vatId",staticClass:"input-fix mb-4",attrs:{"outlined":"","dense":"","error-messages":_vm.vatIdErrors,"placeholder":_vm.$t('subscription.enterVatId')},on:{"blur":function () {
            this$1.$v.vatId.$touch();
            _vm.validateVatInput();
          },"change":function () {
            _vm.vatServiceValidated = false;
            _vm.validateVatInput;
          },"keyup":[_vm.validateVatInput,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
            _vm.validateVatService();
            // @ts-ignore
            _vm.$refs.vatId.blur();
          })($event)}]},model:{value:(_vm.vatId),callback:function ($$v) {_vm.vatId=$$v},expression:"vatId"}}),(_vm.isEUCountry)?_c(VBtn,{attrs:{"elevation":"0","color":"primary","disabled":_vm.vatServiceValidated,"loading":_vm.vatServiceLoading},on:{"click":_vm.validateVatService}},[_c('span',[_vm._v(_vm._s(_vm.$t("subscription.confirmVatId")))])]):_vm._e(),(_vm.vatServiceValidated)?_c('div',{staticClass:"ml-4 d-inline-block input-fix"},[_c('span',{staticClass:"v-messages__message"},[_c(VIcon,{staticClass:"mr-1 mb-1",attrs:{"color":"primary"}},[_vm._v("mdi-check-circle")]),_vm._v(_vm._s(_vm.$t("subscription.vatIdValidated")))],1)]):_vm._e()],1):_vm._e()],1),_c(VDivider,{staticClass:"my-4"}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"elevation":"0","color":"accent"},on:{"click":_vm.cancel}},[_c('span',{staticClass:"main--text"},[_vm._v(_vm._s(_vm.$t("subscription.cancel")))])]),_c(VBtn,{attrs:{"elevation":"0","color":"primary","disabled":!_vm.nextEnabled},on:{"click":_vm.next}},[_c('span',[_vm._v(_vm._s(_vm.$t("subscription.next")))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }